import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import { Notify } from 'vant';
import 'vant/lib/index.css';
import moment from 'moment'
Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.use(Vant);
Vue.use(Notify);

router.beforeEach((to, from, next) => {
  if (to.path === '/singleSignOn' || to.path === '/errorPage' || to.path === '/details' || to.path === '/noAccess')  {
    next()
  } else {
    if (sessionStorage.getItem('token')) {
      next()
    } else {
      next({path: '/errorPage'})
    }
  }
  // next()
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
